* {

    font-family: $font-a;
    user-select: none;
    outline: 0 !important;

}

input[type=text],
input[type=email],
input[type=number],
textarea { user-select: text; }

.g-button {

    cursor: pointer;
    padding: 10px;
    font-size: 16px;
    text-decoration: none;
    border-radius: 0;
    border: 0;
    box-shadow: 0;
    box-sizing: border-box;

}
