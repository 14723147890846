header {

    padding: 0 20px;
    display: flex;
    align-items: center;
    position: relative;
    background-image: $gradient-1;

    /* Small only */
    @media screen and (max-width: 39.9375em) {

        padding: 0;

    }

    /* Medium only */
    @media screen and (min-width: 40em) and (max-width: 63.9375em) {

        padding: 0;

    }

    .header-tab {

        width: 40px;
        height: 40px;
        flex-shrink: 0;
        display: none;
        background-image: url(/images/control/burger.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 20px;

        /* Small only */
        @media screen and (max-width: 39.9375em) {

            display: block;

        }

        /* Medium only */
        @media screen and (min-width: 40em) and (max-width: 63.9375em) {

            display: block;

        }

        &.active { background-image: url(/images/control/burger-close.svg); }

    }

    .logo {

        width: 250px;
        height: 40px;
        margin-right: auto;
        flex-shrink: 0;
        background-image: url(/images/logo-header.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;

        /* Small only */
        @media screen and (max-width: 39.9375em) {

            height: 35px;
            margin: 0 auto;

        }

        /* Medium only */
        @media screen and (min-width: 40em) and (max-width: 63.9375em) {

            height: 35px;
            margin: 0 auto;

        }

    }

    .header-menu {

        top: 100%;
        left: 0;
        z-index: 3;

        /* Small only */
        @media screen and (max-width: 39.9375em) {

            min-width: 250px;
            display: none;
            position: absolute;
            background-image: $gradient-1;

        }

        /* Medium only */
        @media screen and (min-width: 40em) and (max-width: 63.9375em) {

            min-width: 250px;
            display: none;
            position: absolute;
            background-image: $gradient-1;

        }

        &.active { display: block; }

        > ul {

            margin: 0;
            padding: 0;
            display: flex;
            list-style: none;

            /* Small only */
            @media screen and (max-width: 39.9375em) {

                display: block;

            }

            /* Medium only */
            @media screen and (min-width: 40em) and (max-width: 63.9375em) {

                display: block;

            }

        }

        ul > li {

            &:hover {

                background-color: rgba($main-bg, .15);

            }

        }

        ul > li > a {

            padding: 20px;
            display: block;
            text-decoration: none;
            color: $header-text;

        }

    }

    .visually-impaired-button {
        display: block;
        background : {
            image: url('/images/accessibility.svg');
            size: contain;
            position: center;
            repeat: no-repeat;
        }
        width: 30px;
        height: 30px;
        margin-left: auto;
        border: none;
        background-color: transparent;
        cursor: pointer;

        @media screen and (max-width: 39.9375em) {
            margin-right: 10px;
        }
    }

}
