footer {

    padding: 40px 20px;
    display: flex;
    flex-wrap: wrap;
    background-color: $main-bg;
    background-image: url(/images/background/background-footer.png);
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 532px 100%;

    /* Small only */
    @media screen and (max-width: 39.9375em) {

        display: block;

    }

    .logo {

        width: 275px;
        height: 45px;
        flex-shrink: 0;
        background-image: url(/images/logo-footer.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;

        /* Small only */
        @media screen and (max-width: 39.9375em) {

            margin: 0 auto;

        }

    }

    .nav-block {

        flex-grow: 1;
        display: flex;
        justify-content: center;

        /* Small only */
        @media screen and (max-width: 39.9375em) {

            display: block;
            text-align: center;

        }

    }

    a {

        padding: 5px 0;
        display: block;
        text-decoration: none;
        color: $global-text;

    }

    .footer-menu {

        padding-right: 30px;

        /* Small only */
        @media screen and (max-width: 39.9375em) {

            padding: 0;

        }

        ul {

            margin: 0;
            padding: 0;
            list-style: none;

        }

    }

    .info-block {

        > div {

            cursor: pointer;
            padding: 5px 0;
            display: block;
            text-decoration: none;
            color: $global-text;

        }

    }

}
