@import 'global/const.scss';
@import 'global/elements.scss';
@import 'header/header.scss';
@import 'footer/footer.scss';

body {

    width: 100%;
    margin: 0;
    padding: 0;
    background-image: url(/images/background/background.png);

}

header,
main,
footer {

    max-width: 940px;
    margin: 0 auto;
    box-sizing: border-box;

    /* Medium only */
    @media screen and (min-width: 40em) and (max-width: 63.9375em) {

        max-width: 100%;

    }

}

main {

    display: block;
    background-color: $main-bg;

}

.popup-window .closer {

    width: 20px;
    height: 20px;
    top: 30px;
    right: 30px;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    background-color: transparent;
    background-image: url(/images/control/closer.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

}

.appointment-form {

    max-width: 660px;
    padding: 40px 60px;

    /* Small only */
    @media screen and (max-width: 39.9375em) {

        padding: 40px 10px 10px;

    }

    .appointment-title {

        margin: 0 0 30px;
        font-size: 1.875em;
        font-weight: 600;
        text-transform: uppercase;
        color: $global-text;

        /* Small only */
        @media screen and (max-width: 39.9375em) {

            font-size: 24px;
            margin-bottom: 10px;

        }

    }

    .appointment-block {

        display: flex;
        flex-wrap: wrap;

    }

    input[type=text],
    input[type=date],
    select,
    textarea {

        margin: 10px;
        padding: 10px;
        color: $global-text;
        border: 1px solid $form-br;
        box-sizing: border-box;
        background-color: $main-bg;

    }

    input[type=text],
    input[type=date],
    select,
    .g-button,
    .appointment-text {

        flex-basis: calc(50% - 20px);

        /* Small only */
        @media screen and (max-width: 39.9375em) {

            flex-basis: 100%;

        }

    }

    select {

        apperance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        background-image: url(/images/control/arrow-down.svg);
        background-repeat: no-repeat;
        background-position: calc(100% - 15px) center;
        background-size: 13px 8px;

    }

    textarea {

        width: 100%;
        min-width: 0;
        height: 115px;
        box-sizing: border-box;

    }

    .privacy { display: none; }

    .privacy:checked ~ .privacy-label:before {

        background: url(/images/control/check.svg) no-repeat center;

    }

    .privacy-label {

        cursor: pointer;
        margin: 10px;

        &:before {

            content: "";
            display: inline-block;
            width: 15px;
            height: 15px;
            margin-right: 5px;
            border: 1px solid $global-bg;
            background-color: #fff;

        }

        a {

            color: $global-bg;
            text-decoration: none;
            font-size: 14px;

        }

    }

    .g-button {

        margin: 10px;
        color: $header-text;
        background-color: $global-bg;

    }

    .appointment-text {

        margin: 10px;
        color: $global-text;

    }

}

/* Small only */
@media screen and (max-width: 39.9375em) {}

/* Medium and up */
@media screen and (min-width: 40em) {}

/* Medium only */
@media screen and (min-width: 40em) and (max-width: 63.9375em) {}

/* Large and up */
@media screen and (min-width: 64em) {}

/* Large only */
@media screen and (min-width: 64em) and (max-width: 74.9375em) {}
